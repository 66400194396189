import React, { Component } from "react";

export class Contact extends Component {

  constructor(props){
    super(props)
    this.state = {
      name: '',
      email: '',
      message: ''
    }

    this.updateInput = this.updateInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  updateInput(event){
    this.setState({[event.target.id]: event.target.value})
  }

  handleSubmit(){
    let uri = 'mailto:doctorkvietnam@gmail.com'
    uri += '?subjet='
    uri += encodeURIComponent(this.state.name + ' - ' + this.state.email)
    uri += '&body='
    uri += encodeURIComponent(this.state.message)
    window.open(uri)
  }

  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Liên hệ</h2>
                  <p>
                    Hãy điền thông tin vào biểu mẫu bên dưới, chúng tôi sẽ phản hồi sớm nhất có thể.
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Tên"
                          required="required"
                          onChange={this.updateInput}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="tel"
                          id="email"
                          className="form-control"
                          placeholder="Điện thoại"
                          required="required"
                          // pattern="[0-9]{4}-[0-9]{3}-[0-9]{3}"
                          onChange={this.updateInput}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Nội dung"
                      required
                      onChange={this.updateInput}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg"
                    onClick={this.handleSubmit}>
                    Gửi thông tin
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Thông tin</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Địa chỉ
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Điện thoại
                  </span>{" "}
                  {this.props.data ? (<a href={"tel:" + this.props.data.phone} style={{color: 'hotpink'}}>{this.props.data.phone}</a>): "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {this.props.data ? (<a href={"mailto:" + this.props.data.email} style={{color: 'hotpink'}}>{this.props.data.email}</a>) : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "/"}
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.twitter : "/"}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.youtube : "/"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2020 Issaaf Kattan React Land Page Template. Design by{" "}
              <a href="http://www.templatewire.com" rel="nofollow">
                TemplateWire
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
