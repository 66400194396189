import React, { Component } from "react";

export class Gallery extends Component {
  render() {

    const images = ['182efdd591da618438cb4.jpg',
      '1c562a95449ab4c4ed8b15.jpg',
      '4c2d2b50445fb401ed4e8.jpg',
      '57a254503a5fca01934e12.jpg',
      '5d800f40614f9111c85e14.jpg',
      '6e8bd35bbf544f0a16456.jpg',
      '6e8dc67da872582c016311.jpg',
      '95d80c7a6275922bcb649.jpg',
      '9740bcbed2b122ef7ba013.jpg',
      'ad9aef33a33c53620a2d.jpg',
      'baa2ab70c57f35216c6e17.jpg',
      'c1dcb417da182a46730916.jpg',
      'd155dfadb3a243fc1ab33.jpg',
      'f2e7322b5e24ae7af7355.jpg',
      'f3639a51db5e2b00724f.jpg',
      'fc1ac676ab795b2702681.jpg',
      '68bfb6dbb4a846f61fb92.jpg',
      '8cd101b403c7f199a8d63.jpg',
      'a652fe37fc440e1a57551.jpg',
      '4db9e7f8e28b10d5499a7.jpg',
      '62da709b75e887b6def95.jpg',
      '75e27fa07ad3888dd1c26.jpg',
      '98b2c5f2c08132df6b909.jpg',
      'bd0b1a4a1f39ed67b4288.jpg']

    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Hình ảnh</h2>
            <p>
              Một số hình ảnh về sản phẩm và quá trình sản xuất.
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/01-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                      target="_blank"
                    >
                      <div className="hover-text">
                        <h4>Phóng lớn</h4>
                      </div>
                      <img
                        src="img/portfolio/01-small.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/03-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                      target="_blank"
                    >
                      <div className="hover-text">
                        <h4>Phóng lớn</h4>
                      </div>
                      <img
                        src="img/portfolio/03-small.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/04-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                      target="_blank"
                    >
                      <div className="hover-text">
                        <h4>Phóng lớn</h4>
                      </div>
                      <img
                        src="img/portfolio/04-small.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/05-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                      target="_blank"
                    >
                      <div className="hover-text">
                        <h4>Phóng lớn</h4>
                      </div>
                      <img
                        src="img/portfolio/05-small.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/06-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                      target="_blank"
                    >
                      <div className="hover-text">
                        <h4>Phóng lớn</h4>
                      </div>
                      <img
                        src="img/portfolio/06-small.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/07-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                      target="_blank"
                    >
                      <div className="hover-text">
                        <h4>Phóng lớn</h4>
                      </div>
                      <img
                        src="img/portfolio/07-small.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/08-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                      target="_blank"
                    >
                      <div className="hover-text">
                        <h4>Phóng lớn</h4>
                      </div>
                      <img
                        src="img/portfolio/08-small.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/09-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                      target="_blank"
                    >
                      <div className="hover-text">
                        <h4>Phóng lớn</h4>
                      </div>
                      <img
                        src="img/portfolio/09-small.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/02-large.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                      target="_blank"
                    >
                      <div className="hover-text">
                        <h4>Phóng lớn</h4>
                      </div>
                      <img
                        src="img/portfolio/02-small.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              {images.slice(0).reverse().map((image)=>(
                <div className="col-sm-6 col-md-4 col-lg-4">
                  <div className="portfolio-item">
                    <div className="hover-bg">
                      {" "}
                      <a
                        href={"img/sanpham2/" + image }
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                        target="_blank"
                      >
                        <div className="hover-text">
                          <h4>Phóng lớn</h4>
                        </div>
                        <img
                          src={"img/sanpham2/" + image }
                          className="img-responsive"
                          alt="Project Title"
                        />{" "}
                      </a>{" "}
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    <video className="img-responsive" controls>
                      <source src="img/sanpham2/907944047582965483.mp4" type="video/mp4"/>
                    </video>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    <video className="img-responsive" controls>
                      <source src="img/sanpham2/390042278993270040910.mp4" type="video/mp4"/>
                    </video>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    <video className="img-responsive" controls>
                      <source src="img/sanpham2/12754145795452671834.mp4" type="video/mp4"/>
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
