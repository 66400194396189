import React, { Component } from "react";

export class Navigation extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Mở menu</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="page-scroll" href="#page-top">
              <img src='../img/slide/slide1.png' style={{height: '50px'}}/>
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="#features" className="page-scroll">
                  Sản phẩm
                </a>
              </li>
              <li>
                <a href="#about" className="page-scroll">
                  Giới thiệu
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll">
                  Chứng nhận
                </a>
              </li>
              <li>
                <a href="#portfolio" className="page-scroll">
                  Hình ảnh
                </a>
              </li>
              {/* <li>
                <a href="#testimonials" className="page-scroll">
                  Testimonials
                </a>
              </li> */}
              <li>
                <a href="#team" className="page-scroll">
                  Nhân sự
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  Liên hệ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
